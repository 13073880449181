<template>
  <div class="mt-3">
    <div v-if="currentWorkspace?.connected">
      <!-- <div class="mt-3"> -->
      <div
        class="border-b"
        :class="$vuetify.breakpoint.lgAndUp ? 'd-flex justify-end' : ''"
      >
        <!-- <v-col cols="6"></v-col> -->
        <!-- <v-row class="border-b"> -->
        <div class="d-flex justify-end align-center mb-5">
          <!-- <v-col lg="3" md="6" sm="12" class="mx-0 px-1 pb-0">
          <v-select
          v-model="modelHeaders"
          :items="optionalHeaders"
          label="Select Columns"
          class="request-filter elevation-0 rounded-lg"
          multiple
          value="item"
          dense
          hide-details
          outlined
          v-on:change="changeMainHeader"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip class="ma-0 pa-0" v-if="index < 1">
              <span class="font-style-paragraph font-weight-regular px-1">{{
                item.text | truncate(8)
              }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text caption font-weight-regular ma-0 pa-0"
              >(+{{ modelHeaders.length - 1 }} others)</span
            >
          </template>
        </v-select>
        </v-col> -->
          <!-- <v-col cols="6"></v-col> -->
          <!-- <v-col cols="3" class="text-end mx-0 px-1 pb-0"> -->
          <div>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              outlined
              dense
              class="rounded-lg"
            ></v-text-field>
            <!-- </v-col> -->
          </div>

          <!-- <v-col col="3" class="mb-6"> -->
          <div>
            <div class="d-flex flex-row justify-end align-center">
              <!-- <FilterButton/> -->
              <v-btn
                id="v-step-2"
                class="custom-btn f-inter fw-600 fs-22"
                @click="$router.push({ path: 'generate-request' })"
                ><v-img src="@/assets/ticket/receipt.svg"></v-img
                ><span
                  style="letter-spacing: 0px"
                  class="fw-600 fs-12 f-inter ml-2 text-none"
                  >Record Now
                </span></v-btn
              >
              <div class="text-center">
                <v-menu offset-y :close-on-content-click="false" v-model="menu">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on">
                      <TransactionSettings />
                    </v-btn>
                  </template>
                  <v-container class="settings">
                    <v-sheet
                      class="f-inter fs-14 fw-600 mt-2 mb-3"
                      style="color: var(--v-lightPurple-base)"
                      >Default</v-sheet
                    >
                    <!-- Top checkbox content -->
                    <v-row no-gutters>
                      <v-col
                        cols="6"
                        v-for="(item, index) in optionalHeaders"
                        :key="index"
                        v-show="item.showInDefault"
                      >
                        <v-checkbox
                          class="mt-0 mb-3"
                          :label="item.text"
                          hide-details
                          v-model="item.default"
                          :ripple="false"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-divider class="border-opacity-25"></v-divider>
                    <v-sheet
                      class="f-inter fs-14 fw-600 mt-3 mb-3"
                      style="color: var(--v-lightPurple-base)"
                      >More Columns</v-sheet
                    >
                    <!-- Bottom checkbox content -->
                    <!-- v-for="(item, index) in (retainedValue ? optionalHeaders : moreColumnsRetainedValue)" -->
                    <v-row no-gutters>
                      <v-col
                        cols="6"
                        v-for="(item, index) in optionalHeaders"
                        :key="index"
                        v-show="item.showInList && !item.showInDefault"
                      >
                        <v-checkbox
                          class="mt-0 mb-3"
                          :label="item.text"
                          hide-details
                          v-model="item.default"
                          :ripple="false"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <div class="d-flex flex-row-reverse mt-3">
                      <v-btn
                        class="custom-btn ml-3 text-none"
                        @click="addModelHeaders"
                        ><span class="fs-12 fw-500 f-inter lightPurple--text"
                          >Save</span
                        ></v-btn
                      >
                      <v-btn
                        class="custom-cancel-btn ml-3 text-none"
                        @click="resetCheckbox()"
                        ><span class="fs-12 fw-500 f-inter subHeading--text"
                          >Cancel</span
                        ></v-btn
                      >
                    </div>
                  </v-container>
                </v-menu>
              </div>
            </div>
            <!-- </v-col> -->
          </div>
        </div>
        <!-- </v-row> -->
      </div>
      <v-data-table
        :headers="mainHeaders"
        :items="items"
        :loading="loader"
        :search="search"
        fixed-header
        :height="$vuetify.breakpoint.xs || items.length > 10 ? '67.6vh' : ''"
        class="text-no-wrap mt-2"
      >
        <!-- :class="$vuetify.breakpoint.lgAndUp ? 'mt-5' : 'mt-2'" -->
        <template
          v-for="(header, index) in mainHeaders"
          v-slot:[`header.${header.value}`]="{ header }"
        >
          <th class="fs-14 fw-700 f-inter totalBlack--text">
            {{ header.text }}
          </th>
        </template>
        <!-- <template
        v-for="(item, index) in items"
        v-slot:[`item.${item.value}`]="{ item }"
        >
          <th class="fs-14 fw-700 f-inter text-decoration-none totalBlack--text">{{ item.text }}</th>
        </template> -->
        <!-- <template v-slot:item.name="{ item }">
          <div class="fs-14 fw-700">{{ item.name }}</div>
        </template> -->

        <template v-slot:body="{ items }" class="mb-5 pb-5">
          <template v-for="item in items">
            <tr class="fs-12 fw-400 f-inter">
              <td
                v-for="(headers, index2) in mainHeaders"
                :key="index2 + 'A'"
                class="pl-1 bottom-tr-td pt-1 pb-1"
              >
                <v-col>
                  <span v-if="headers.value === 'contact.name'">
                    {{ item.contact.name }}
                  </span>
                  <!-- <span v-if="headers.value === 'date'"> -->
                    <!-- <template v-slot:[`item.date`]="{ item }"> -->
                    <span v-if="headers.value === 'createdDate'" class="textPrimary--text">
                      {{ item.date | formatISODate }}
                    </span>
                    <!-- </template> -->
                  <!-- </span> -->
                  <span v-if="headers.value === 'dueDate'">
                    <!-- <template v-slot:[`item.dueDate`]="{ item }"> -->
                    <span class="textPrimary--text">
                      {{ item.dueDate | formatISODate }}
                    </span>
                    <!-- </template> -->
                  </span>
                  <span v-else>
                    {{ item[headers.value] }}
                  </span>
                </v-col>
              </td>
            </tr>
          </template>
        </template>

        <!-- <template v-slot:[`item.date`]="{ item }">
        <span class="textPrimary--text">
          {{ item.date | formatISODate }}
        </span>
        </template> -->
        <!-- <template v-slot:[`item.dueDate`]="{ item }">
          <span class="textPrimary--text">
            {{ item.dueDate | formatISODate }}
          </span>
        </template> -->
        <template v-slot:[`item.isDiscounted`]="{ item }">
          <div class="d-flex justify-center">
            <v-icon v-if="item.isDiscounted" color="lightGreen">
              mdi-check-circle
            </v-icon>
            <v-icon v-else color="red"> cancel </v-icon>
          </div>
        </template>
        <template v-slot:[`item.hasAttachments`]="{ item }">
          <div class="d-flex justify-center">
            <v-icon v-if="item.hasAttachments" color="lightGreen">
              mdi-check-circle
            </v-icon>
            <v-icon v-else color="red"> cancel </v-icon>
          </div>
        </template>
      </v-data-table>
    </div>
    <no-software-connected v-if="!currentWorkspace?.connected" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../services/Api";
import xeroTransactionsHeaderList from "../../constants/xeroTransactionsHeaderList.js";
import TransactionSettings from "../../assets/svg-icons/transaction-page-setings.vue";
import NoSoftwareConnected from "../common/connect-software/NoSoftwareConnected.vue";

export default {
  components: {
    TransactionSettings,
    NoSoftwareConnected,
  },
  data() {
    return {
      modelHeaders: [],
      allHeadersWithShowInList: [],
      mainHeaders: [],
      allHeaders: xeroTransactionsHeaderList,
      search: "",
      loader: false,
      items: [],
      invType: "",
      menu: false,
    };
  },
  props: {
    transactionType: String,
  },
  watch: {
    $route: {
      handler(val) {
        this.invType = val.query.type;
        this.fetchAccountsFromDB();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // console.log(this.$route.query.type, "QUERY");
    // this.invType = this.$route.query.type;
    this.addModelHeaders();
    this.fetchAccountsFromDB();
    console.log("all headers", this.allHeaders);
    console.log("main headers", this.mainHeaders);
  },
  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
      user: "auth/getUser",
      workspaces: "auth/getUserWorkSpaces",
    }),
    optionalHeaders() {
      return this.allHeaders.filter(
        (item) => item.hasOwnProperty("showInList") && item.showInList === true
      );
    },
  },
  methods: {
    resetCheckbox() {
      for (let header of this.allHeaders) {
        if (header.showInList && !header.showInDefault) {
          header.default = false;
        } else if (header.showInList && header.showInDefault) {
          header.default = true;
        }
      }
      // for(let v of this.allHeaders) {
      //   if(v.showInList && v.showInDefault){
      //     v.default = true;
      //   }
      // };
      this.addModelHeaders();
      this.menu = false;
    },
    addModelHeaders() {
      console.log("save button function");
      this.allHeadersWithShowInList = this.allHeaders.filter(
        (item) => item.default && !item.showInList
      );
      const newData = this.allHeaders.filter(
        (item) => item.showInList && item.default
      );
      this.modelHeaders = newData.map((item) => item.value);
      this.modifyMainHeaders(newData);
      this.menu = false;
    },
    changeMainHeader() {
      const newData = this.allHeaders.filter((option) =>
        this.modelHeaders.includes(option.value)
      );
      this.modifyMainHeaders(newData);
    },
    modifyMainHeaders(newData) {
      console.log("modify header");
      this.mainHeaders = [...this.allHeadersWithShowInList, ...newData];
      let lastIndex = this.mainHeaders.length - 1;
      for (let i = 0; i < lastIndex; i++) {
        if (this.mainHeaders[i].value === "action") {
          const actionItem = this.mainHeaders.splice(i, 1)[0];
          this.mainHeaders.push(actionItem);
          break; // Stop loop once action is moved to the end
        }
      }
    },
    // Method to fetch chart of accounts from database
    async fetchAccountsFromDB() {
      try {
        this.loader = true;

        const response = await ApiService.GetRequest(
          `report/transactions/${this.currentWorkspace.workspaceId}?type=${this.invType}`
          // `report/transactions/${this.currentWorkspace.workspaceId}?type=${this.transactionType}`
        );
        this.items = response.data.data;
        this.loader = false;
      } catch (error) {
        this.loader = false;
        // this.$showNotificationBar(
        //   "error",
        //   error.response.data.message ? error.response.data.message : error
        // );
      }
    },
  },
};
</script>

<style scoped>
.custom-cancel-btn {
  border: 1px solid var(--v-borderColor-base);
  background-color: white !important;
  border-radius: 8px;
  min-height: 35px;
  line-height: 19px;
  padding: 10px 20px 10px 20px;
  box-shadow: none;
  margin-left: 10px;
}
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  box-shadow: none !important;
}
:deep .theme--light.v-label {
  font-size: 12px !important;
  color: rgba(0, 0, 0, 1) !important;
}

.bottom-tr-td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
  /* margin-bottom: 15px; */
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 0px !important;
  border-radius: 8px !important;
}
.settings {
  width: 342px;
  height: 520px;
  background-color: white;
}
.border-b {
  border-bottom: 1px solid #d9d9d9;
}
.custom-btn {
  background-color: rgba(53, 16, 167, 0.05) !important;
  border-radius: 8px;
  min-height: 35px;
  line-height: 19px;
  color: var(--v-lightPurple-base) !important;
  padding: 10px 20px 10px 20px;
  box-shadow: none;
  margin-left: 10px;
}
</style>
