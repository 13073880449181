/* 
** default values needs to be true in order to show in data table at first
** show in List check defines that is customizable or not 
*/
let xeroTransactionsHeaderList = [
    {
        text: "Name",
        value: "contact.name",
        sortable: false,
        default: true,
        showInList: true,
        // showInList: false,
        showInDefault: true,

    },
    {
        text: "Type",
        value: "type",
        sortable: false,
        default: true,
        showInList: true,
        // showInList: false,
        showInDefault: true,

    },
    {
        text: "Description",
        value: "description",
        sortable: false,
        default: false,
        showInList: false,
    },
    {
        text: "Quantity",
        sortable: false,
        value: "quantity",
        default: false,
        showInList: false,
    },
    {
        text: "Unit Amount",
        sortable: false,
        value: "unitAmount",
        default: false,
        showInList: false,
    },
    {
        text: "Line Amount",
        sortable: false,
        value: "lineAmount",
        default: false,
        showInList: false,
    },
    {
        text: "Created At",
        sortable: false,
        value: "createdDate",
        default: true,
        showInList: true,
        showInDefault: true,

    },
    {
        text: "Due Date",
        sortable: false,
        value: "dueDate",
        default: true,
        showInList: true,
        showInDefault: true,

    },
    {
        text: "Invoice No.",
        sortable: false,
        value: "invoiceNumber",
        default: true,
        showInList: true,
        showInDefault: true,

    },
    {
        text: "Sub Total",
        sortable: false,
        value: "subTotal",
        default: true,
        showInList: true,
        showInDefault: true,

    },
    {
        text: "Total Tax",
        sortable: false,
        value: "totalTax",
        default: false,
        showInList: true,
    },
    {
        text: "Total",
        sortable: false,
        value: "total",
        default: true,
        showInList: true,
        showInDefault: true,

    },
    {
        text: "Tax Type",
        sortable: false,
        value: "taxType",
        default: false,
        showInList: true,
    },
    {
        text: "Tax Amount",
        sortable: false,
        value: "taxAmount",
        default: false,
        showInList: true,
    },
    {
        text: "Line Amount Types",
        sortable: false,
        value: "lineAmountTypes",
        default: false,
        showInList: true,
    },
    {
        text: "Reference",
        sortable: false,
        value: "reference",
        default: false,
        showInList: true,
    },
    {
        text: "Currency Code",
        sortable: false,
        value: "currencyCode",
        default: false,
        showInList: true,
    },
    {
        text: "Currency Rate",
        sortable: false,
        value: "currencyRate",
        default: false,
        showInList: true,
    },
    {
        text: "Status",
        sortable: false,
        value: "status",
        default: true,
        showInList: true,
        showInDefault: true,

    },
    {
        text: "Attachments",
        sortable: false,
        value: "hasAttachments",
        default: false,
        showInList: true,
    },
    {
        text: "Discounted",
        sortable: false,
        value: "isDiscounted",
        default: false,
        showInList: true,
    },
    {
        text: "Action",
        value: "action",
        sortable: false,
        default: false,
        showInList: false,
    },
]

export default xeroTransactionsHeaderList
