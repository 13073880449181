<template>
  <v-container
    fluid
    style="margin-top: 0px !important"
    class="pt-0 pb-0 pl-0 pr-0 overflow-hidden"
  >
    <!-- <v-row> -->
    <div class="d-flex flex-row">
      <!-- <v-col
        :cols="drawer ? 2 : 1" -->

      <div
        class="pr-0"
        :style="
          $vuetify.breakpoint.xs
            ? 'width: 0 !important'
            : drawer
            ? 'width:195px;'
            : 'width:62px;'
        "
      >
        <div
          :style="
            $vuetify.breakpoint.xs
              ? 'display: block !important; margin-top: 47px !important'
              : 'display: none !important'
          "
        >
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text color="lightPurple" v-bind="attrs" v-on="on">
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in items"
                :key="index"
                :to="item.to"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <v-navigation-drawer
          permanent
          :value="drawer"
          :mini-variant="mini"
          mini-variant-width="62px"
          width="195"
          style="top: 68px !important"
        >
          <!-- fixed -->
          <v-list nav>
            <v-list-item-group>
              <v-btn
                class="mb-3 ms-1"
                icon
                @click="
                  mini = !mini;
                  drawer = !drawer;
                "
              >
                <NavigationOpen
                  :style="drawer ? 'transform: rotate(180deg)' : ''"
                ></NavigationOpen>
              </v-btn>
              <v-list-item
                class="px-0 drawer-tile"
                :class="item.pathName === $route.path && drawer ? 'pl-1' : ''"
                :style="drawer ? 'width: 195px;' : 'width: 48px;'"
                v-for="(item, index) in items"
                :key="index"
                :to="item.to"
                :ripple="false"
              >
                <v-list-item-icon
                  class="nav-item-icon-space mr-3 mt-4"
                  :style="
                    item.pathName === $route.path
                      ? 'margin-right: 16px !important'
                      : 'margin-left: 19px !important'
                  "
                >
                  <transactionImage
                    :item="item"
                    class="d-flex flex-row align-center"
                  />
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    class="f-inter fw-400 fs-14"
                    :class="
                      item.pathName === $route.path
                        ? 'lightPurple--text'
                        : 'dark--text'
                    "
                    >{{ item.title }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </div>
      <!-- :class="$vuetify.breakpoint.xs ? '' : 'ml-4 mr-4'" -->
      <div
        :style="
          drawer
            ? $vuetify.breakpoint.lgAndUp
              ? 'width: 86%;'
              : 'width: 100% !important'
            : 'width: 95%;'
        "
      >
        <RouterView class="pl-0 pr-0"></RouterView>
      </div>
    </div>
  </v-container>
</template>

<script>
import GenerateRequests from "../views/dashboard/GenerateRequests.vue";
import Transactions from "../components/transactions/index.vue";
import NavigationOpen from "../components/common/icons/navigationDrawerOpen.vue";
import transactionImage from "../assets/svg-icons/all-transaction.vue";
import Invoices from "../assets/svg-icons/invoices.svg";
import Bills from "../assets/svg-icons/bills.svg";
import Payments from "../assets/svg-icons/payments.svg";
import { RouterView } from "vue-router";
import { mapGetters } from "vuex";
import socket from "../services/SocketIo";

export default {
  name: "Reports",

  components: {
    GenerateRequests,
    Transactions,
    NavigationOpen,
    RouterView,
    transactionImage,
  },
  data() {
    return {
      drawer: true,
      mini: false,
      selectedIcon: "Transaction",
      items: [],
    };
  },
  // watch: {
  //   $route: {
  //     handler(val) {
  //       const parent = val.matched[val.matched.length - 2];
  //       localStorage.setItem("prevRoute", parent.name);
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  // },

  created() {
    this.subscribe();
  },
  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
      user: "auth/getUser",
      workspaces: "auth/getUserWorkSpaces",
      fetchingStatus: "dashboard/getFetchingStatus",
      token: "auth/getAuthToken",
    }),
    accountingSoftwareModal() {
      return (
        ![
          "Login",
          "Register",
          "Integration",
          "ForgetPassword",
          "Verification",
          "VerifyEmail",
          "LoginVerification",
          "ChangePassword",
        ].includes(this.$route.name) &&
        !this.currentWorkspace.isAccountingSoftConnected &&
        this.user.role === "client" &&
        this.workspaces.length > 0
      );
    },

    sideBar() {
      let user = this.user;
      let items = [
        {
          title: "All Transactions",
          to: "/transaction",
          pathName: "/transaction",
          routeName: "Transactions",
          auth: false,
        },
        {
          ...(!user.isEmployee && !this.fetchingStatus
            ? {
                title: "Invoices",
                to: "/transaction/invoice?type=ACCREC",
                pathName: "/transaction/invoice",
                routeName: "Invoice",
                auth: false,
                icon: Invoices,
              }
            : {}),
        },
        {
          ...(!user.isEmployee && !this.fetchingStatus
            ? {
                title: "Bills",
                to: "/transaction/bill?type=ACCPAY",
                pathName: "/transaction/bill",
                routeName: "Bill",
                auth: false,
                icon: Bills,
              }
            : {}),
        },
        {
          ...(!user.isEmployee && !this.fetchingStatus
            ? {
                title: "Payments",
                pathName: "/transaction/payment",
                to: "/transaction/payment?type=PAID",
                routeName: "Payment",
                auth: false,
                icon: Payments,
              }
            : {}),
        },
      ];
      return items;
    },
  },
  methods: {
    subscribe() {
      socket.on("xero-connection-processing", (body) => {
        console.log(body, "on fetching in transaction");
        if (this.token && this.currentWorkspace._id == body.workspaceId) {
          this.$store.commit("dashboard/fetchingData", body.isProcessing);
          this.items = this.sideBar;

          // this.$store.commit("dashboard/fetchingData", body.isProcessing);
          // this.$store.commit("auth/updateConnectedStatus", {
          //   connected: body.connected ? body.connected : false,
          //   workspaceId: body?.workspaceId,
          // });
        }
      });
    },
  },
  mounted() {
    console.log(this.fetchingStatus, "SIODE");
    this.items = this.sideBar;
    // this.$router.push("transaction");
  },
};
</script>
<style scoped>
.theme--light.v-list-item--active::before {
  opacity: 0;
}
.drawer-tile {
  height: 48px;
  width: 48px;
}
.nav-item-icon-space {
  align-self: center;
}
</style>
