var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0 pb-0 pl-0 pr-0 overflow-hidden",staticStyle:{"margin-top":"0px !important"},attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"pr-0",style:(_vm.$vuetify.breakpoint.xs
          ? 'width: 0 !important'
          : _vm.drawer
          ? 'width:195px;'
          : 'width:62px;')},[_c('div',{style:(_vm.$vuetify.breakpoint.xs
            ? 'display: block !important; margin-top: 47px !important'
            : 'display: none !important')},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"lightPurple"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}])},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.to}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),_c('v-navigation-drawer',{staticStyle:{"top":"68px !important"},attrs:{"permanent":"","value":_vm.drawer,"mini-variant":_vm.mini,"mini-variant-width":"62px","width":"195"}},[_c('v-list',{attrs:{"nav":""}},[_c('v-list-item-group',[_c('v-btn',{staticClass:"mb-3 ms-1",attrs:{"icon":""},on:{"click":function($event){_vm.mini = !_vm.mini;
                _vm.drawer = !_vm.drawer;}}},[_c('NavigationOpen',{style:(_vm.drawer ? 'transform: rotate(180deg)' : '')})],1),_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,staticClass:"px-0 drawer-tile",class:item.pathName === _vm.$route.path && _vm.drawer ? 'pl-1' : '',style:(_vm.drawer ? 'width: 195px;' : 'width: 48px;'),attrs:{"to":item.to,"ripple":false}},[_c('v-list-item-icon',{staticClass:"nav-item-icon-space mr-3 mt-4",style:(item.pathName === _vm.$route.path
                    ? 'margin-right: 16px !important'
                    : 'margin-left: 19px !important')},[_c('transactionImage',{staticClass:"d-flex flex-row align-center",attrs:{"item":item}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"f-inter fw-400 fs-14",class:item.pathName === _vm.$route.path
                      ? 'lightPurple--text'
                      : 'dark--text'},[_vm._v(_vm._s(item.title))])],1)],1)})],2)],1)],1)],1),_c('div',{style:(_vm.drawer
          ? _vm.$vuetify.breakpoint.lgAndUp
            ? 'width: 86%;'
            : 'width: 100% !important'
          : 'width: 95%;')},[_c('RouterView',{staticClass:"pl-0 pr-0"})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }